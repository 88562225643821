<div class="flex flex-col">
  <div>
    <label class="mb-4 font-bold text-12 text-middleGray">{{ label }}</label>

    @if (!showAddressInput && hasBothAddressLines) {
      <div class="flex float-right -mt-10 h-33">
        <cartwheel-button
          label="Update Address"
          type="text-button"
          color="secondary"
          (onClick)="enableShowAddressInput()"
        />
      </div>
    }
  </div>

  @if (showAddressInput) {
    <input
      class="w-full px-8 font-bold bg-white border rounded outline-none h-33 text-darkerGray text-14 border-lightGray"
      ngx-gp-autocomplete
      type="text"
      id="fullAddress"
      [value]="fullAddress"
      (onAddressChange)="handleAddressChange($event)"
      [placeholder]="hasBothAddressLines ? fullAddress : placeholder"
      [options]="options"
    />
  } @else if (hasBothAddressLines) {
    <div class="w-full px-8 font-bold bg-white outline-none h-33 text-darkerGray text-14">
      {{ fullAddress }}
    </div>
  } @else {
    <div class="flex h-33">
      <cartwheel-button label="Add Address" type="text-button" color="secondary" (onClick)="enableShowAddressInput()" />
    </div>
  }
</div>
