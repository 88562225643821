// import { Address } from 'ngx-google-places-autocomplete/objects/address';

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CartwheelButtonComponent } from '@cartwheel/web-components';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from 'environments/environment';

@Component({
  standalone: true,
  selector: 'app-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss'],
  imports: [CartwheelButtonComponent, NgxGpAutocompleteModule],
  providers: [
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: environment.google.mapApiKey,
        libraries: ['places']
      })
    }]
})
export class AddressAutocompleteComponent implements OnChanges {
  @Input() shouldInitialized: boolean = true;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() addressGroup: { address1?: string, address2?: string };
  @Output() private onAddressChange: EventEmitter<{ address1: string, address2: string }> = new EventEmitter<{ address1: string, address2: string }>();

  public fullAddress: string = '';
  public hasBothAddressLines: boolean = false;
  public showAddressInput: boolean = false;
  public options: any = {
    componentRestrictions: { country: ['US', 'CA'] },
    fields: ['address_components'],
    types: ['address'],
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.addressGroup?.currentValue) {
      this.hasBothAddressLines = this.checkAddressLines();
      this.fullAddress = this.hasBothAddressLines ? `${changes.addressGroup.currentValue.address1}, ${changes.addressGroup.currentValue.address2}` : '';
    }
    if (changes.shouldInitialized?.currentValue || !changes.shouldInitialized?.currentValue) {
      this.showAddressInput = !this.shouldInitialized;
    }
  }

  public handleAddressChange(address: any): void {
    let subpremise, street_number, route, locality, administrative_area_level_1, country;

    address.address_components.forEach((component) => {
      component.types.forEach((type) => {
        switch(type) {
          case 'subpremise':
            subpremise = component['short_name'];
            break;
          case 'street_number':
            street_number = component['short_name']
            break;
          case 'route':
            route = component['long_name'];
            break;
          case 'locality':
            locality = component['short_name'];
            break;
          case 'administrative_area_level_1':
            administrative_area_level_1 = component['short_name'];
            break;
          case 'country':
            country = component['short_name'] === 'US' ? 'USA' : component['long_name'];
            break;
          default:
        }
      })
    });

    const address1 = [street_number, route, subpremise].filter(value => !!value).join(" ");
    const address2 = [locality, administrative_area_level_1, country].filter(value => !!value).join(", ");

    this.onAddressChange.emit({ address1, address2 });
  }

  public enableShowAddressInput(): void {
    this.showAddressInput = true; 
  }

  private checkAddressLines(): boolean {
    return !this.isEmpty(this.addressGroup.address1) && !this.isEmpty(this.addressGroup.address2);
  }

  private isEmpty(value): boolean {
    return (value == null || (typeof value === "string" && value.trim().length === 0));
  }
}
